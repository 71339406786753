
import moment from 'moment';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill';
import PricePlanModal from '@/components/modals/forms/PricePlanModel.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { defineComponent, onMounted, ref, computed } from 'vue';
// @import utils
import { IUserLevel } from '@/core/data/userLevel';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import {PricePlan} from "@/core/data/pricePlan";
// @import components

export default defineComponent({
	name: 'Update-Priceplan-Content',
	components: {
		QuillEditor,
		PricePlanModal
	},
	setup() {
		const route = useRoute();
		const store = useStore();
		const selectedPricingPlans = ref<any>([]);

		const resetPricingPlansObject = itemId => {
			selectedPricingPlans.value = selectedPricingPlans.value.filter(
				e => e.pricePlanId !== itemId
			);
		};

		
		const setSelectedPricePlan = item => {
			selectedPricingPlans.value.push(item);
		};

		

		const isEdit = route.params.id;

		const contentEditor = ref<any>(null);
		const descriptionEditor = ref<any>(null);
		const formRef = ref<null | HTMLFormElement>(null);

		const loading = ref<boolean>(false);
		const userLevel = ref<Array<IUserLevel>>([]);

		const formData = ref({
			pricePlanId: '',
			title: '',
			displayTitle: '',
			buttonUrl: '',
			description: '',
			subtitle: '',
			payOff: '',
			features: '',
			slug: '',
			buttonLabel: '',
			publicationDate: '',
			price: '',
			discountPrice: '',
			planDurationValue: 0,
			planDurationUnits: 'Hours',
			planType: 'PerViewSingle',
			published: false,
			stripePriceId: '',
			stripePriceTestId: '',
			stripeCouponId: '',
			stripeCouponTestId: '',
			importMatch: '',
			sortOrder: 0,
			public: false,
			userLevels: [],
			created: '',
			updated: '',
			relatedPricePlans: []
		});

		const rules = ref({
			title: [
				{
					required: true,
					message: 'Title is required',
					trigger: 'change'
				}
			],
			displayTitle: [
				{
					required: true,
					message: 'Display Title is required',
					trigger: 'change'
				}
			]
		});

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		onMounted(() => {
			setCurrentPageBreadcrumbs(`${isEdit ? 'Edit' : 'Add'} Priceplan`, [
				'Priceplan'
			]);
			if (isEdit) {
				getPricePlanByID();
			}
			getUserLevels();
		});

		const getPricePlanByID = () => {
			ApiService.vueInstance.axios.get(`PricePlan/${route.params.id}?isFetchingRelatedPricePlan=true`)
				.then(({ data }) => {
					formData.value = {
						...formData.value,
						...data,
            planDurationValue: data.planDuration.value,
            planDurationUnits: data.planDuration.unit,
						userLevels:
							(data.userLevels != undefined &&
								data.userLevels.indexOf(',') != -1) ||
							(data.userLevels != undefined && data.userLevels != '')
								? data.userLevels.split(',')
								: (data.userLevels = [])
					};
					data.relatedPricePlans.forEach(element => {
						selectedPricingPlans.value.push(element);
					});
					contentEditor.value.setHTML(data.features);
					descriptionEditor.value.setHTML(data.description);
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const getUserLevels = () => {
			ApiService.get('UserLevel')
				.then(({ data }) => {
					// console.log('UserLevel response: ', data);
					userLevel.value = data;
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const copy = async () => {
			var text = formData.value.title + " || " + formData.value.pricePlanId;
			await navigator.clipboard.writeText(text);
		}
		const submit = () => {
			if (!formRef.value) {
				return;
			}
			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;
					
					const pricingPlansIds = selectedPricingPlans.value.map(
					item => item.pricePlanId
					);
					formData.value.relatedPricePlans = pricingPlansIds.join(',');

					const data: PricePlan = {
						...formData.value,
						customerId: selectedCustomer.value,
						userLevels: formData.value.userLevels.toString(),
						publicationDate: moment(formData.value.publicationDate).format(
							'YYYY-MM-DD'
						),
						planDuration: {unit: formData.value.planDurationUnits as PricePlanDurationUnit, value: formData.value.planDurationValue},
						pricePlanId: formData.value.pricePlanId,
						relatedPricePlans: pricingPlansIds
					};
					let serviceType;
					if (isEdit) {
						serviceType = ApiService.put(`PricePlan/${route.params.id}`, data);
					} else {
					delete data['pricePlanId'];
					serviceType = ApiService.post('PricePlan', data);
					}

					serviceType
						.then(({ data }) => {
							// console.log('create PricePlan response', data);
							loading.value = false;
							// redirect to main page ?
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								if (isEdit) {
									formData.value = {
										...formData.value,
										...data,
										userLevels:
											data.userLevels.indexOf(',') != -1 ||
											data.userLevels != ''
												? data.userLevels.split(',')
												: (data.userLevels = [])
									};
								} else {
									(
										document.getElementById('formRef') as HTMLFormElement
									).reset();
								}
							});
						})
						.catch(({ response }) => {
							console.log('create PricePlan error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		return {
			rules,
			moment,
			isEdit,
			copy,
			submit,
			resetPricingPlansObject,
			setSelectedPricePlan,
			formRef,
			loading,
			formData,
			userLevel,
			contentEditor,
			descriptionEditor,
			selectedPricingPlans
		};
	}
});
